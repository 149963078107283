import { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { ParticleBanner } from 'components/ParticleBanner'
import { ReleasePreviewWrapper } from 'components/ReleasePreviewWrapper'
import { HomeDetails, HomeDetailsRelease } from 'components/HomeDetails'
import { Edges, ContentfulRelease } from 'types'
import { removeEdges, RichTextType } from 'helpers'

export interface IndexPageProps {
  data: {
    releases: Edges<ContentfulRelease>
    upcomingReleases: Edges<ContentfulRelease>
    page: {
      sectionOne: RichTextType
      config: {
        bannerText: string
        bandcampUrl: string
        soundcloudUrl: string
        spotifyUrl: string
      }
    }
  }
}

export const IndexPage: FunctionComponent<IndexPageProps> = ({ data }) => {
  const releases = removeEdges(data.releases)
  const upcomingReleases = removeEdges(data.upcomingReleases)

  const homeConfig = data.page.config

  return (
    <>
      <ParticleBanner bannerText={homeConfig.bannerText} />
      <ReleasePreviewWrapper releases={releases} />
      <HomeDetails
        releases={releases as HomeDetailsRelease[]}
        upcomingReleases={upcomingReleases as HomeDetailsRelease[]}
        welcomeRichText={data.page.sectionOne}
        bandcamp={homeConfig.bandcampUrl}
        spotify={homeConfig.spotifyUrl}
        soundcloud={homeConfig.soundcloudUrl}
      />
    </>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    page: contentfulPage(name: { eq: "home" }) {
      sectionOne {
        raw
      }
      config: pageConfig {
        bannerText
        bandcampUrl
        soundcloudUrl
        spotifyUrl
      }
    }
    releases: allContentfulRelease(
      filter: { isFuture: { eq: false } }
      sort: { order: DESC, fields: [releaseDate] }
      limit: 3
    ) {
      edges {
        node {
          title
          artist {
            name
            slug
          }
          releaseDate(formatString: "Do MMMM, YYYY")
          releaseId
          bandcampId
          previewAddress
          shortDescription {
            raw
          }
          cover {
            fluid(maxWidth: 790, sizes: "(max-width: 850px) 94vw, 320px") {
              ...GatsbyContentfulFluid
            }
          }
          backCover {
            fluid(maxWidth: 623) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    upcomingReleases: allContentfulRelease(
      filter: { isFuture: { eq: true } }
      sort: { order: ASC, fields: [releaseDate] }
      limit: 3
    ) {
      edges {
        node {
          title
          artist {
            name
            slug
          }
          releaseDate(formatString: "Do MMMM, YYYY")
          releaseId
          shortDescription {
            raw
          }
        }
      }
    }
  }
`
