import { ReactNode } from 'react'
import { useModal } from 'components/modal'
import { ModalImage } from 'components/ModalImage'

export const useModalImage = (): ((image: ReactNode) => void) => {
  const [setModalContents, onModalClosed] = useModal()

  function setModalImage(image: ReactNode) {
    if (document) {
      const currentFocusedElement = document!.activeElement as HTMLElement
      if (currentFocusedElement) {
        onModalClosed(() => currentFocusedElement!.focus())
      }

      setModalContents(<ModalImage data-testid="modal-img" image={image} />)
    }
  }

  return setModalImage
}
