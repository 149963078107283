import { FunctionComponent } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Preview } from 'components/ReleasePreview'
import { ContentfulRelease } from 'types'
import { useModalImage } from 'components/hooks/useModalImage'
import { constants, mixins } from 'styles'

const StyledReleaseWrapper = styled.section`
  ${mixins.innerWrapperMixin}
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2%;
  margin-top: 3rem;
  @media ${constants.breakpoints.maxHeader} {
    display: block;
    .release-preview {
      margin-bottom: 4rem;
    }
  }
  @media ${constants.breakpoints.mobile} {
    display: block;
    .release-preview {
      margin-bottom: 2rem;
    }
  }
`

export interface ReleasePreviewWrapperProps {
  releases: ContentfulRelease[]
}

export const ReleasePreviewWrapper: FunctionComponent<ReleasePreviewWrapperProps> = ({ releases }) => {
  const setModalImage = useModalImage()

  return (
    <StyledReleaseWrapper data-testid="preview-wrapper">
      {releases.map((release) => {
        const onImageClick = () => {
          const backCover = <Img alt={`${release.releaseId} back cover image`} fluid={release.backCover.fluid} />
          setModalImage(backCover)
        }

        const cover = <Img alt={`${release.releaseId} cover image`} fluid={release.cover.fluid} />

        return (
          <Preview
            className="release-preview"
            key={release.releaseId}
            releaseId={release.releaseId}
            title={release.title}
            artist={release.artist.name}
            slug={release.artist.slug}
            bandcampId={release.bandcampId}
            previewAddress={release.previewAddress}
            image={cover}
            onImageClick={onImageClick}
          />
        )
      })}
    </StyledReleaseWrapper>
  )
}
