import { ReactNode, FunctionComponent } from 'react'
import styled from 'styled-components'
import { constants, colours } from 'styles'

const StyledModalImage = styled.div`
  background-color: ${colours.black};
  padding: 10px;
  width: 50%;
  max-height: 100vh;
  overflow-y: auto;
  @media ${constants.breakpoints.maxHeader} {
    width: 80%;
  }
`

export interface ModalImageProps {
  image: ReactNode
}

export const ModalImage: FunctionComponent<ModalImageProps> = ({ image }) => (
  <StyledModalImage data-testid="modal-img">{image}</StyledModalImage>
)
