import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import bandcampPNG from 'images/bandcamp.png'
import spotifyPNG from 'images/spotify.png'
import { colours, mixins } from 'styles'
import { useGlobalContext } from 'components/store/globalContext'
import { getComponentFromRT, RichTextType } from 'helpers'

const OuterWrapperWelcome = styled.div`
  background: ${colours.white};
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 5rem;
`

const OuterWrapperListen = styled.div`
  background: ${colours.greyFive};
  padding-top: 1rem;
  padding-bottom: 2rem;
`

const InnerWrapper = styled.div`
  ${mixins.innerWrapperMixin}
  ${mixins.infoGrid}
  ${mixins.textSection}
`

const ReleasesWrapper = styled.div`
  p {
    margin-bottom: 0;
    font-size: 1.6rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  .release-date {
    font-weight: 400;
    font-style: italic;
    font-size: 1.4rem;
  }
  .release {
    margin-bottom: 1.5rem;
  }
  .release-link {
    position: relative;
    font-size: 1.4rem;
    top: -0.5rem;
  }
`

const ListenWrapper = styled.div`
  img {
    height: 40px;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
  a {
    border-bottom: none;
  }
`

function getReleasePreview(release: HomeDetailsRelease) {
  return (
    <div className="release" key={release.releaseId}>
      <h3>
        {release.releaseId} : {release.artist.name} - {release.title}
      </h3>
      <span className="release-date">{release.releaseDate}</span>
      {getComponentFromRT(release.shortDescription)}
      <Link className="release-link" to={`/artists/${release.artist.slug}/#${release.releaseId}`}>
        Read more
      </Link>
    </div>
  )
}

export interface HomeDetailsRelease {
  artist: {
    name: string
    slug: string
  }
  title: string
  releaseDate: Date
  releaseId: string
  shortDescription: RichTextType
}

export interface HomeDetailsProps {
  releases: HomeDetailsRelease[]
  upcomingReleases: HomeDetailsRelease[]
  welcomeRichText: RichTextType
  bandcamp: string
  spotify: string
  soundcloud: string
}

export const HomeDetails: FunctionComponent<HomeDetailsProps> = ({
  releases,
  upcomingReleases,
  welcomeRichText,
  bandcamp,
  spotify,
  soundcloud,
}) => {
  const [{ cookiesAccepted }] = useGlobalContext()

  return (
    <>
      <OuterWrapperWelcome>
        <InnerWrapper>
          <ReleasesWrapper>
            <h2>Out Now</h2>
            {releases.map((release) => getReleasePreview(release))}
          </ReleasesWrapper>
          <div>{getComponentFromRT(welcomeRichText)}</div>
        </InnerWrapper>
      </OuterWrapperWelcome>
      <OuterWrapperListen>
        <InnerWrapper>
          <ListenWrapper>
            <h2>Listen To TruthTable</h2>
            <div>
              <a href={bandcamp} target="_blank" rel="noreferrer nofollow noopener">
                <img src={bandcampPNG} alt="Bandcamp" />
              </a>
              <a href={spotify} target="_blank" rel="noreferrer nofollow noopener">
                <img src={spotifyPNG} alt="Spotify" />
              </a>
              {cookiesAccepted && <iframe width="100%" height="300" scrolling="no" allow="autoplay" src={soundcloud} />}
            </div>
          </ListenWrapper>
          {upcomingReleases.length > 0 && (
            <ReleasesWrapper>
              <h2>Upcoming Releases</h2>
              {upcomingReleases.map((release) => getReleasePreview(release))}
            </ReleasesWrapper>
          )}
        </InnerWrapper>
      </OuterWrapperListen>
    </>
  )
}
