import { FunctionComponent } from 'react'
import ParticleAnim, { IParticlesParams } from 'react-particles-js'
import styled from 'styled-components'
import { colours } from 'styles'

const params: IParticlesParams = {
  particles: {
    number: {
      value: 70,
      density: {
        enable: true,
        value_area: 600,
      },
    },
    color: {
      value: colours.darkBlack,
    },
    shape: {
      type: 'circle',
      stroke: {
        width: 0,
        color: colours.darkBlack,
      },
      polygon: {
        nb_sides: 4,
      },
    },
    opacity: {
      value: 0.5,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 3,
      random: true,
      anim: {
        enable: false,
        speed: 40,
        size_min: 0.1,
        sync: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 150,
      color: colours.greyTwo,
      opacity: 0.4,
      width: 1,
    },
    move: {
      enable: true,
      speed: 6,
      direction: 'none',
      random: true,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 600,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: true,
        mode: 'grab',
      },
      onclick: {
        enable: true,
        mode: 'push',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 250,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 600,
        size: 80,
        duration: 2,
        opacity: 8,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
}

export interface ParticleProps {
  children?: never
  width: string
  height: string
}

const StyledParticles = styled(ParticleAnim)<ParticleProps>`
  position: absolute;
  left: 0;
  background-color: ${colours.white};
  z-index: -1;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  box-sizing: content-box;
  border-bottom: 0.4rem solid rgba(32, 32, 32, 0.1);
`

export const Particles: FunctionComponent<ParticleProps> = (props) => <StyledParticles {...props} params={params} />
