import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { fontLato, colours, constants } from 'styles'
import { Particles } from './Particles'

export interface ParticleBannerProps {
  children?: never
  bannerText: string
}

const BannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colours.black};
  height: ${constants.particleBannerHeight};
  .tag-line {
    ${fontLato};
    font-size: 6rem;
    font-weight: bold;
    opacity: 0.7;
    text-align: center;
    color: ${colours.orange};
    letter-spacing: 8px;
    padding: 0 2rem;
    @media ${constants.breakpoints.maxHeader} {
      font-size: 4rem;
    }
  }
`

export const ParticleBanner: FunctionComponent<ParticleBannerProps> = ({ bannerText }) => (
  <>
    <Particles width="100%" height={constants.particleBannerHeight} />
    <BannerWrapper>
      <h1 className="tag-line">{bannerText}</h1>
    </BannerWrapper>
  </>
)
