import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { colours } from 'styles'
import ImageIcon from 'images/image-icon.svg'
import LinkIcon from 'images/link-icon.svg'

const iconStyles = css`
  border-radius: 50%;
  color: ${colours.white};
  display: inline-block;
  width: 6rem;
  height: 6rem;
`

export const ImageButton = styled.button`
  ${iconStyles}
  background: ${colours.black} url(${ImageIcon}) no-repeat center;
  background-size: 30%;
  margin-right: 10px;
  :focus {
    outline: none;
    border: 2px solid white;
  }
`

export const ReleaseLink = styled(Link)`
  ${iconStyles}
  background: ${colours.black} url(${LinkIcon}) no-repeat center;
  background-size: 50%;
  margin-left: 10px;
`
