import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { colours, constants } from 'styles'
import { getBandCampEmbedAddress } from 'helpers'
import { ImageButton, ReleaseLink } from 'components/ReleaseIcons'
import { useGlobalContext } from 'components/store/globalContext'

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  .bandcamp {
    border: 0;
    width: 100%;
    height: 42px;
    margin-top: 0.6rem;
  }
`

const PreviewDetails = styled.div`
  background-color: ${colours.white};
  height: 100%;

  .details {
    padding: 1.8rem 1.5rem 1.8rem;
    font-size: 1.8rem;
  }

  .title {
    display: inline-block;
    min-width: 100px;
    color: ${colours.greyOne};
    font-weight: 600;
    @media ${constants.breakpoints.maxHeader} {
      font-size: 2rem;
    }
  }

  .name {
    color: ${colours.greyOne};
    font-weight: 300;
  }

  hr {
    background: ${colours.greyThree};
    border: 0 none;
    height: 1px;
    margin: 0.6rem 0 0 0;
    width: 4.2rem;
  }

  .cover {
    position: relative;
  }

  .cover-buttons {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 400ms ease 0s;
  }

  :hover .cover-buttons {
    opacity: 1;
  }
  :hover hr {
    background: ${colours.black};
  }
`

export interface PreviewProps {
  artist: string
  slug: string
  title: string
  releaseId: string
  bandcampId: string
  previewAddress: string
  image: ReactNode
  onImageClick: () => void
  children?: never
  className?: string
}

export const Preview: FunctionComponent<PreviewProps> = ({
  artist,
  slug,
  title,
  releaseId,
  bandcampId,
  previewAddress,
  image,
  onImageClick,
  className,
}) => {
  const [{ cookiesAccepted }] = useGlobalContext()
  return (
    <PreviewWrapper className={className}>
      <PreviewDetails>
        <div className="cover">
          {image}
          <div className="cover-buttons">
            <ImageButton data-testid="cover-btn" onClick={onImageClick}>
              <span className="visually-hidden">Show Cover Image</span>
            </ImageButton>
            <ReleaseLink to={`/artists/${slug}/#${releaseId}`}>
              <span className="visually-hidden">Go To Release Page</span>
            </ReleaseLink>
          </div>
        </div>
        <div className="details">
          <a href={previewAddress} target="_blank" rel="noreferrer nofollow noopener">
            <h4 className="title">{title}</h4>
          </a>
          <p className="name">
            {artist}, {releaseId}
          </p>
          <hr />
        </div>
      </PreviewDetails>

      {cookiesAccepted && (
        <iframe className="bandcamp" seamless src={getBandCampEmbedAddress(bandcampId!)}>
          <a href={previewAddress} target="_blank" rel="noreferrer nofollow noopener">
            {title} by
            {artist}
          </a>
        </iframe>
      )}
    </PreviewWrapper>
  )
}
